<!-- 关注 -->
<template>
  <div class="shop">
    <div>
      <van-list
        v-model="isUpLoading"
        :finished="upFinished"
        :immediate-check="false"
        :offset="10"
        finished-text="我是有底线的"
        @load="onLoadList"
        style="padding: 15px 15px 50px 15px; height: 100%"
      >
        <div
          v-for="item in goodsList"
          class="goods-item"
          v-if="dataStatus"
        >
          <!-- <img :src="imageUrl+ item.image" alt="" class="goods-image"> -->
          <van-swipe-cell>
            <div style="display: flex; align-items: center; padding: 5px 15px">
              <!-- <img :src="imageUrl + item.image" alt="" class="goods-image" /> -->
              <img :src="item.image" alt="" class="goods-image" >
              <div class="shop-info">
                <span class="goods-name">{{ item.name }}</span>
                <div class="shop-address-item">
                  <!-- <van-icon name="location" style="width:0.3rem;height:0.3rem"/> -->
                  <span class="shop-address">{{ item.address }}</span>
                </div>
              </div>
            </div>
            <template #right>
              <van-button
                square
                type="danger"
                text="删除"
                @click="delList(item.id)"
                class="delete-button"
              />
            </template>
          </van-swipe-cell>
        </div>
        <van-empty :image="emptyImg" description="暂无关注" v-else />
      </van-list>
    </div>
  </div>
</template>
<script>
import { toUrl } from "@/utils/tools";
import { concernList, concernDel } from "@/api/user";
import http from "@/utils/config";
export default {
  data() {
    return {
      dataStatus: true,
      emptyImg: require("@/assets/zwsc.png"),
      shopType: "",
      nullTip: "空空如也~",
      limit: 10, // 每页条数
      page: 1, // 页码
      type: 1,
      goodsList: [
        {
          image: "/upload/image/20210412/dc6f67d53e279c4235f809abab28b18f.jpg",
          address: "郑州金水区",
          name: "哈哈",
        },
      ], // 请求数据
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      imageUrl: http.imageUrl,
    };
  },
  mounted() {
    this.concernList();
  },
  methods: {
    indexDetails(id) {
    	console.log(id)
    	this.$router.push({
    		path: "/h5/commDetail",
    		query: {
    			id: id
    		}
    	})
    },
    concernList() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let params = {};
      params.page = this.page;
      params.limit = this.limit;
      concernList(params).then((res) => {
        console.log(res);
        const rows = res.data.data;
        // console.log(rows.length)
        if (rows.length === 0) {
          // 加载结束
          this.upFinished = true;
          if (this.page == 1) {
            this.dataStatus = false;
            this.$toast.clear();
          }
          return;
        } else {
          this.dataStatus = true;
        }
        if (rows.length < this.limit) {
          // 最后一页不足10条的情况
          this.upFinished = true;
        }
        // 处理数据
        if (this.page === 1) {
          this.goodsList = rows;
        } else {
          this.goodsList = this.goodsList.concat(rows);
        }
        // console.log(this.goodsList)
        this.$toast.clear();
      });
    },
    onDownRefresh() {},
    delList(id) {
      // console.log(id)
      let params = {};
      params.sid = id;
      concernDel(params).then((res) => {
        if (res.code == 200) {
          // console.log(res)
          this.page = 1;
          this.upFinished = false;
          this.goodsList = [];
          this.concernList();
        }
      });
    },
    // 上拉加载请求方法
    onLoadList() {
      this.page++;
      this.concernList();
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/preset.less";

.shop {
  .options-area {
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;

    .options {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 20%;
      margin-bottom: 10px;

      .options-icon {
        width: 55px;
        height: 55px;
      }

      .options-name {
        margin-top: 5px;
        font-size: 12px;
        color: #666666;
      }
    }
  }
  .goods-item::v-deep(.van-swipe-cell__right) {
    height: 100% !important;
    top: 0 !important;
	right: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .goods-item {
    display: flex;
    align-items: flex-start;
    background-color: #ffffff;

    border-radius: 5px;
    margin-bottom: 10px;

    justify-content: flex-start;
    .delete-button {
      height: 100% !important;
    }
    .goods-image {
      width: 88px;
      height: 88px;
      border-radius: 8px;
    }

    .shop-info {
      margin-left: 10px;
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .goods-name {
        font-size: 16px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .shop-address-item {
        display: flex;
        align-items: center;

        .shop-address {
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }
}

.van-swipe-cell {
  width: 100%;
  height: 100%;
  line-height: 44px;
  // background: rgba(0,0,0,0.15);
}
</style>
